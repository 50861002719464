import React from 'react'
import mision from '../img/foto-mision.jpg'
const MisionVision = (props) => (
    <div className="container">
        <div className="columns">
            <div className="column">
                <hr />
            </div>
        </div>
        <div className="columns">
            <div className="column is-6" style={{ height: '100%' }}>
            <br /><br />
                <p className="title is-2 has-text-centered">Misión</p>
                <p className="subtitle is-block has-text-justified">
                    Ofrecer construcción industrial comercial y servicios que supere las expectativas de nuestros clientes con personal altamente capacitado, tecnología de punta y proveedores certificados; impactando positivamente las comunidades y el medio ambiente de los sitios donde operamos.
                        </p>
                        <br />
                        <hr />
                        <br />
                <p className="title is-2 has-text-centered">Visión</p>
                <p className="subtitle is-block has-text-justified">
                    Convertirnos en la  mejor y más eficiente empresa de construcciones y servicios, liderando el mercado por medio de la responsabilidad, y eficiencia, cumpliendo a tiempo con todos y cada uno de los trabajos encomendados, lograr que todo nuestro personal se sienta motivado y orgulloso de pertenecer a nuestra organización, fomentando el control y la calidad en el servicio, buscando siempre dar más de sí mismos y con esto lograr la satisfacción del cliente.
                        </p>
            </div>
            <div className="column is-6">
                <figure class="image">
                    <img src={mision} alt="Misión" />
                </figure>
            </div>
        </div>

        <div className="columns">
            <div className="column" >
                <br /><br />
            </div>
        </div>
    </div>
)

export default MisionVision