import React from 'react';
import Valores from './Valores.js'
import header from '../img/foto-header-servicios.png';
import logojpv from '../img/LOGO-JPV.png';
import logojavasa from '../img/LOGO-JAVASA.png';
import logoanpaka from '../img/LOGO-ANPAKA.png';
import logojaimes from '../img/LOGO-JAIMES.png';
import logocheckcar from '../img/LOGO-CHECKCAR.png';

export default function Servicios() {
    return (
        <>
            <section className="hero is-dark is-medium is-bold has-background">
                <img alt="Sercicios" class="hero-background is-transparent" src={header} />
                <div className="hero-body">
                    <div className="container">
                        <p className="title">
                            Servicios
                        </p>
                        <p className="subtitle">
                            A continuación los servicios que prestamos.
                        </p>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container is-fullhd">
                    <div className="columns is-vcentered">
                        <div className="column is-offset-2 is-4">
                            <figure class="image is-2by1">
                                <img src={logojaimes} alt="JAIMES - Construcciones y Servicios" />
                            </figure>
                        </div>
                        <div className="column is-6">
                            <ul>
                                <li>Construcción.</li>
                                <li>Ingeniería.</li>
                                <li>Gerencia y fiscalización de proyectos.</li>
                                <li>Operaciones y mantenimiento.</li>
                                <li>Procura y logística.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="columns is-vcentered">
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logoanpaka} alt="Anpaka" />
                            </figure>
                        </div>

                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logojpv} alt="JPV - Facilidades para la construcción" />
                            </figure>
                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logocheckcar} alt="CHECKCAR - Mecánica rápida de vehículos" />
                            </figure>
                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logojavasa} alt="JAVASA" />
                            </figure>

                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3 is-vcentered">
                            <p className="has-text-centered">
                                <b>
                                Intermediación en la compra, venta y alquiler de Bienes Inmuebles.
                                </b>
                            </p>

                        </div>
                        <div className="column is-3 is-vcentered">
                            <p className="has-text-centered">
                                <b>
                                    Alquiler de maquinaria  y equipos para la construcción, minería e industria hidrocarfurifera.
                                </b>
                            </p>
                        </div>
                        <div className="column is-3  is-vcentered">
                            <p className="has-text-centered">
                                <b>
                                Mecánica General<br />
                                Inyección electrónica y Mecánica. <br />
                                Enderezada y Pintura
                                </b>
                            </p>
                        </div>
                        <div className="column is-3 is-vcentered">
                            <p className="has-text-centered">
                                <b>
                                    Comercialización de insumos limpieza, desinfección, y bioseguridad.
                                </b>
                            </p>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column" >
                            <hr />
                        </div>
                    </div>
                </div>
                <Valores />
            </section>
        </>
    )
}