import React from 'react'
import { faLightbulb,faCogs,faThumbsUp,faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Valores = (props) => (
    <div className="container is-fluid container-background container-margin">
        <div className="overlay">
        <div className="columns is-vcentered is-desktop is-multiline is-centered">
            <div className="column">
                <p className="subtitle is-1 has-text-centered white-1">
                    Valores
                </p>
            </div>
        </div>
        <div className="columns is-vcentered">
            <div className="column">
                <br />
            </div>
        </div>
        <div className="columns is-vcentered is-desktop is-multiline is-centered">
            <div className="column">
                <div className="box is-primary">
                    <p className="subtitle is-4 has-text-centered">
                        <FontAwesomeIcon icon={faPeopleArrows} size="3x" />
                        <br />
                        <br />
                    Espíritu de Servicio</p>
                    <p className="has-text-centered">
                        Comprender, atender y satisfacer las necesidades de nuestros clientes, proveedores, asociados y colaboradores.
                        </p>
                </div>
            </div>
            <div className="column">
                <div className="box  is-primary">
                    <p className="subtitle is-4 has-text-centered">
                        <FontAwesomeIcon icon={faThumbsUp} size="3x" />
                        <br />
                        <br />
                    Compromiso
                    </p>
                    <p className="has-text-centered">
                        Cumplir y superar los requerimientos y expectativas de nuestros clientes.
                        <br /><br />
                        </p>
                </div>
            </div>
            <div className="column">
                <div className="box is-primary" >
                    <p className="subtitle is-4 has-text-centered">
                        <FontAwesomeIcon icon={faLightbulb} size="3x" />
                        <br />
                        <br />
                        Innovación
                    </p>
                    <p className="has-text-centered">
                        Promovemos el cambio constante en nuestros procesos y servicios para estar en la mejora continua.
                        <br /><br /></p>
                </div>
            </div>
            <div className="column">
                <div className="box is-primary">
                    <p className="subtitle is-4 has-text-centered">
                        <FontAwesomeIcon icon={faCogs} size="3x" />
                        <br />
                        <br />
                    Esfuerzo continuo
                    </p>
                    <p className="has-text-centered">
                        Compromiso de nuestro personal para en cada instante dar lo mejor de nosotros mismos.
                        <br /><br /></p>
                </div>
            </div>
        </div>
        </div>
    </div>
)

export default Valores