import React from 'react'
import estrategia from '../img/foto-estrategia.jpg';
const Estrategia = (props) => (
    <div className="container">
         <div className="columns is-desktop is-multiline is-vcentered is-centered">
            <div className="column">
                <hr />
            </div>
        </div>
        <div className="columns is-desktop is-multiline is-vcentered is-centered">
            <div className="column">
                <p className="subtitle is-1 has-text-centered brown-1">
                    Estrategia
                </p>
            </div>
        </div>
        <div className="columns">
            <div className="column is-6" >
                <figure class="image is-2by1">
                    <img src={estrategia} alt="estrategia" />
                </figure>
            </div>
            <div className="column is-6 is-vcentered">
<p className="subtitle is-block has-text-justified">La mejor estrategia con la que contamos es nuestro equipo de trabajo capital humano y nos preocupamos porque todos estemos bien preparados en el campo particular que nos desenvolvemos.</p>
<p className="subtitle is-block has-text-justified">Nuestra especialidad es hacer las cosas bien ¡Y nos ha resultado excelente! Tenemos un porcentaje perfecto en cuanto a repartición de trabajo con nuestros clientes.</p>
<p className="subtitle is-block has-text-justified">Es así como en Jaimes Construcciones y Servicios nos dedicamos a lo que hacemos mejor...<br /> Construir y dar servicios.</p>
<p className="subtitle is-block has-text-justified">Nuestros clientes lo confirman.</p>
            </div>
        </div>
        <div className="columns">
            <div className="column" >
                <br /><br />
            </div>
        </div>
    </div>
)

export default Estrategia