import React from 'react';
import Valores from './Valores.js'
import header from '../img/foto-header.png';
import quienesomos from '../img/foto-quienes-somos.png';
import logonavbar from '../img/LOGO-JAIMES.png';
// import logonavbar from '../img/logo-navbar.png'

export default function Home() {
    return (
        <>
            <section className="hero is-light is-large is-bold has-background">
                <img alt="Fill Murray" class="hero-background hero-background-home" src={header} />
                <div className="hero-body">
                    <div className="container">
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container is-fullhd">
                    <div className="columns is-vcentered is-desktop is-multiline is-centered">
                        <div className="column">
                            <p className="subtitle is-1 has-text-centered brown-1">
                                
                </p>
                        </div>
                    </div>
                    <div className="columns is-vcentered">
                        <div className="column is-6" >
                            <figure class="image is-2by1">
                                <img  src={quienesomos} alt="QuienesSomos" />
                            </figure>
                        </div>
                        <div className="column is-6">
                            <br />
                            <p className="subtitle is-block has-text-justified">
                                Somos profesionales con más de 33 años de experiencia construyendo proyectos industriales, comerciales, habitacionales y servicios en todo Ecuador.
                            </p>
                            <p className="subtitle is-block has-text-justified">
                                A través del tiempo nos hemos convertido en un lider en las construcciones y servicios, la competencia es muy buena y por esa razón nos mantenemos en constante capacitación y en lo que a la última tecnología se refiere para estar a la altura de nuestros clientes.
                            </p>
                        </div>
                    </div>
                    
                    <div className="columns is-vcentered">
                        <div className="column" >
                            <hr />
                        </div>
                    </div>
                    {/* <div className="columns is-vcentered  is-desktop is-multiline is-centered">
                        <div className="column is">
                            <p className="subtitle is-1 has-text-centered brown-1">
                                Servicios
                            </p>
                        </div>
                    </div>
                    <div className="columns is-vcentered  is-desktop is-multiline is-centered">
                        <div className="column is-offset-2 is-4">
                            <figure class="image is-2by1">
                                <img src={logojaimes} alt="JAIMES - Construcciones y Servicios" />
                            </figure>
                        </div>
                        <div className="column is-6">
                            <ul>
                                <li>Diseño y construcción de edificaciones.</li>
                                <li>Mantenimiento preventivo y correctivo de edificaciones.</li>
                                <li>Servicio de ingeniería, civil, mecanica, electrica, instrumentacion y control.</li>
                                <li>Construcción de obras electromecánicas.</li>
                                <li>Gerenciamiento y fiscalización de proyectos.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="columns is-vcentered">
                        <div className="column" >
                            <hr />
                        </div>
                    </div> */}
                </div>
                <Valores />
            </section>
        </>
    )
}