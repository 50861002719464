import React from 'react';
import header from '../img/bg-quienessomos.jpg';
import quienesomos from '../img/foto-quienes-somos2.jpg';
import MisionVision from "./MisionVision"
import Estrategia from './Estrategia';


export default function Quienessomos() {
    return (
        <>
            <section className="hero is-dark is-medium is-bold has-background">
                <img alt="Fill Murray" class="hero-background is-transparent" src={header} />
                <div className="hero-body">
                    <div className="container">
                        <p className="title">
                            Quienes Somos
                        </p>
                        <p className="subtitle">
                            Información sobre nuestra historia, misión y visión de nuestra empresa.
                        </p>
                    </div>
                </div>
            </section>
            <section className="section is-vcentered">
                <div className="container is-fullhd is-vcentered">
                    <div className="columns">
                        <div className="column is-6" >
                            <figure class="image is-2by1">
                                <img  src={quienesomos} alt="QuienesSomos" />
                            </figure>
                        </div>
                        <div className="column is-6 is-vcentered">
                            <br />
                            <p className="subtitle is-block has-text-justified">
                                Somos profesionales con más de 33 años de experiencia construyendo proyectos industriales, comerciales, habitacionales y servicios en todo Ecuador.
                            </p>
                            <p className="subtitle is-block has-text-justified">
                                A través del tiempo nos hemos convertido en un lider en las construcciones y servicios, la competencia es muy buena y por esa razón nos mantenemos en constante capacitación y en lo que a la última tecnología se refiere para estar a la altura de nuestros clientes.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <MisionVision />
            <Estrategia />
        </>
    )
}