import React from 'react'
import './App.sass'
import { BrowserRouter, Route } from 'react-router-dom'
import Header from "./parts/Header"
import Home from "./components/Home"
import Quienessomos from "./components/Quienessomos"
import Servicios from "./components/Servicios"
import Clientes from "./components/Clientes"
import Contacto from "./components/Contacto"
import Footer from "./parts/Footer"
import Page from 'react-page-loading'
function App() {
    return (
    <Page loader={"bar"} color={"#A9A9A9"} size={4}>
        <BrowserRouter>
        <Header />
            <Route path='/' exact render={Home} />
            <Route path='/quienessomos' exact component={Quienessomos} />
            <Route path='/servicios' exact component={Servicios} />
            <Route path='/clientes' exact component={Clientes} />
        </BrowserRouter>
        <Contacto />
        <Footer />
    </Page>
    )
}
export default App;