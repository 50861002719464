import React from 'react'
import { faPhone, faEnvelope, faLink, faMarker } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import logonavbar from '../img/logo-navbar.png'


const handle = (event) => {
    
    setTimeout(function(){ 
        document.getElementById('send-message').classList.remove("is-hidden") 
        document.getElementById('nombre').value = ''
        document.getElementById('email').value = ''
        document.getElementById('mensaje').value = ''
    }, 2000)
 }

const Contacto = (props) => (
    <div className="container">
        <div className="columns is-vcentered is-centered">
            <div className="columns is-vcentered is-centered">
                <div className="column">
                    <p className="subtitle is-1 has-text-centered brown-1" id="contacto">
                        Ponte en contacto
                </p>
                    <p>Nos encanta recibir mensajes, puedes escribirnos en los datos a continuación o bien llenar el formulario.</p>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <hr />
                </div>
            </div>
        </div>
        <div className="columns">
            <div className="column is-2 has-text-right is-vcentered" ><br /></div>
            <div className="column is-4 has-text-justified is-vcentered" >
                <br />
                <br />
                <br />
                <p><img src={logonavbar} alt="Construcciones y servicios en Ecuador" width="200" /></p>
                <br />
                <p><b><FontAwesomeIcon icon={faPhone} size="1x" /> 02 241 6548</b></p>
                <p><b><FontAwesomeIcon icon={faEnvelope} size="1x" /> info@jaimes.ec</b></p>
                <p><b><FontAwesomeIcon icon={faLink} size="1x" /> https://www.jaimes.ec</b></p>
                <p><b><FontAwesomeIcon icon={faMarker} size="1x" /> Av. 6 de Diciembre N47-236 y Samuel Fritz</b></p>
            </div>
            <div className="column is-6 is-vcentered">
                <p className="subtitle has-text-centered"><b>Envianos un mensaje</b></p>
                <div class="field">
                    <label class="label">Nombre</label>
                    <div class="control">
                        <input class="input" type="text" id="nombre" placeholder="ejemplo: Juan perez" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                        <input class="input" type="email" id="email" placeholder="e.g. juan.perez@email.com" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">Mensaje</label>
                    <div class="control">
                        <textarea class="input" type="email" id="mensaje" placeholder="Necesito información sobre un proyecto"></textarea>
                    </div>
                </div>
                <div class="field has-text-right">
                    <div class="control">
                        <button type="button" onClick={handle} className="button is-success">
                            Enviar
                        </button>
                    </div>
                </div>
                <div class="notification is-warning is-hidden" id="send-message">
                    Mensaje Enviado
                </div>
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <br />
                <br />
            </div>
        </div>
    </div>
)

export default Contacto