import React from 'react'

export default function Footer() {
   return (
      <footer className="footer has-footer-border">
         <div className="content has-text-centered">
            <p>
               © 2020 JAIMES - Construcciones y Servicios. Todos los derechos reservados.<br />
            Diseñado por <a href={"https://www.barstoolco.net"} title={"Barstool & Company"}>Barstool & Company</a>
            </p>
         </div>
      </footer>
   )
}