import React from 'react';
import Valores from './Valores.js'
import header from '../img/foto-header-clientes.png';
import andespetroleum from '../img/clientes/andespetroleum.png';
import clientes_001_smartpro from '../img/clientes/clientes_0001_smartpro.png';
import clientes_0004_petrooriental from '../img/clientes/clientes_0004_petrooriental.png';
import clientes_0005_petrobell from '../img/clientes/clientes_0005_petrobell.png';
import clientes_0006_petroecuador from '../img/clientes/clientes_0006_petroecuador.png';
import clientes_0007_petroamazonas from '../img/clientes/clientes_0007_petroamazonas.png';
import clientes_0008_orion from '../img/clientes/clientes_0008_orion.png';
import clientes_0009_logoazul from '../img/clientes/clientes_0009_logo-azul.png';
import clientes_0014_gobiernoprefecturaorellana from '../img/clientes/clientes_0014_gobiernoprefecturaorellana.png';
import logo_gadibarra from '../img/clientes/logo_gadibarra.png';
import logoconduto from '../img/clientes/logo-conduto.png';
import logoepmaps from '../img/clientes/logo-epmaps.png';
import logogadcotopaxi from '../img/clientes/logo-gadcotopaxi.png';
import logogadnapo from '../img/clientes/logo-gadnapo.jpg';
import logogadpichincha from '../img/clientes/logo-gadpichincha.png';
import logogadrumiñaui from '../img/clientes/logo-gadrumiñaui.png';
import logohaciendachantag from '../img/clientes/logo-haciendachantag.jpg';
import logopasteur from '../img/clientes/logo-pasteur.png';
import logoUnidadeducativaInocencioJacomeibarra from '../img/clientes/logo-UnidadeducativaInocencioJacome-ibarra.png';
import santoscmi from '../img/clientes/santoscmi.png';
import sertecpet from '../img/clientes/sertecpet.png';

export default function Clientes() {
    return (
        <>
            <section className="hero is-dark is-medium is-bold has-background">
                <img alt="Sercicios" class="hero-background is-transparent" src={header} />
                <div className="hero-body">
                    <div className="container">
                        <p className="title">
                            Clientes
                        </p>
                        <p className="subtitle">
                            Algunos clientes con quienes hemos tenido el gusto de compartir.
                        </p>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container is-fullhd">
                    <div className="columns is-vcentered">
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={andespetroleum} alt="" />
                            </figure>
                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={clientes_001_smartpro} alt="" />
                            </figure>

                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={clientes_0004_petrooriental} alt="" />
                            </figure>
                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={clientes_0005_petrobell} alt="" />
                            </figure>

                        </div>
                    </div>
                    <div className="columns is-vcentered">
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={clientes_0006_petroecuador} alt="" />
                            </figure>
                        </div>

                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={clientes_0007_petroamazonas} alt="" />
                            </figure>

                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={clientes_0008_orion} alt="" />
                            </figure>
                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={clientes_0009_logoazul} alt="" />
                            </figure>
                        </div>
                    </div>
                    <div className="columns is-vcentered">
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={clientes_0014_gobiernoprefecturaorellana} alt="" />
                            </figure>
                        </div>

                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logo_gadibarra} alt="" />
                            </figure>

                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logoconduto} alt="" />
                            </figure>
                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logoepmaps} alt="" />
                            </figure>
                        </div>
                    </div>
                    <div className="columns is-vcentered">
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logogadrumiñaui} alt="" />
                            </figure>
                        </div>

                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logogadpichincha} alt="" />
                            </figure>

                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logogadnapo} alt="" />
                            </figure>
                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logogadcotopaxi} alt="" />
                            </figure>
                        </div>
                    </div>
                    <div className="columns is-vcentered">
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={santoscmi} alt="" />
                            </figure>
                        </div>

                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logoUnidadeducativaInocencioJacomeibarra} alt="" />
                            </figure>

                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logopasteur} alt="" />
                            </figure>
                        </div>
                        <div className="column is-3">
                            <figure class="image is-2by1">
                                <img src={logohaciendachantag} alt="" />
                            </figure>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column" >
                            <hr />
                        </div>
                    </div>
                </div>
                <Valores />
            </section>
        </>
    )
}