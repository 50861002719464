import React, { useState } from 'react';
import logonavbar from '../img/logo-navbar.png';
import { Link, animateScroll as scroll } from "react-scroll"
import { NavLink } from 'react-router-dom'

export default function Header() {
    const [isActive, setisActive] = React.useState(false);

    return (
        <header>
            <div className="container is-fullhd has-text-centered">
                <nav className="navbar  is-fixed-top is-spaced" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <a href="https://www.jaimes.ec">
                            <img src={logonavbar} alt="Construcciones y servicios en Ecuador" width="200" />
                        </a>
                        <a href="#top" role="button" aria-labelledby={"menu"}
                            aria-expanded={false} data-target="navbarjaimes" onClick={() => {
                                setisActive(!isActive);
                            }}
                            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
                        >
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                    <div id="navbarjaimes" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                        <div className="navbar-start">

                        </div>
                        <div className="navbar-end">
                            <NavLink
                                to='/'
                                className="navbar-item"
                                exact
                            >
                                Inicio
                            </NavLink>
                            <NavLink
                                to='/quienessomos'
                                className="navbar-item"
                                exact
                            >
                                Quienes Somos
                            </NavLink>
                            <NavLink
                                to='/servicios'
                                className="navbar-item"
                                exact
                            >
                                Servicios
                            </NavLink>
                            <NavLink
                                to='/clientes'
                                className="navbar-item"
                                exact
                            >
                                Clientes
                            </NavLink>
                            <div className="navbar-item is-grouped-centered is-vcentered">
                                <div className="buttons is-centered">
                                    <Link
                                        activeClass=""
                                        className="button is-primary"
                                        to="contacto"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                    >
                                        Contacto
                                            </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="container is-fluid has-border">

            </div>
        </header>
    )
}